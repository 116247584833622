import React, { useState } from 'react';

import { checkoutText } from '../DataFiles/standardText';

import '../MainForm/MainForm.css';
import { convertHeicToJpeg, isHeicImageFile } from '../utils/heicFile';

const PhotoIdForm = (props) => {
  const { photoState, onPhotoReady } = props;

  const [error, setError] = useState('');

  const convertAndReadHeic = async (file, reader) => {
    try {
      const convertedFile = await convertHeicToJpeg(file);
      reader.readAsDataURL(convertedFile);
    } catch (error) {
      console.error('Failed converting file', error);
      setError(
        'There is an issue with this file please try a different format.'
      );
    }
  };

  const handlePhoto = async (e) => {
    setError('');
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      if (isHeicImageFile(file)) {
        await convertAndReadHeic(file, reader);
      } else {
        reader.readAsDataURL(file);
      }

      reader.onload = (e) => {
        const imgElement = document.createElement('img');
        imgElement.src = e.target.result;
        imgElement.onload = (imgE) => {
          const canvas = document.createElement('canvas');
          const maxWidth = 1000 > imgE.target.width ? imgE.target.width : 1000;
          const scaleSize = maxWidth / imgE.target.width;
          canvas.width = maxWidth;
          canvas.height = imgE.target.height * scaleSize;

          const ctx = canvas.getContext('2d');
          ctx.drawImage(imgE.target, 0, 0, canvas.width, canvas.height);

          const encoded = ctx.canvas.toDataURL('image/jpeg');
          const stripped = encoded.replace('data:image/jpeg;base64,', '');
          onPhotoReady({
            ...photoState,
            filename: file.name,
            photo: stripped,
            fullSrc: encoded,
          });
        };
      };
    } else {
      return;
    }
  };

  return (
    <>
      <div className='checkoutFooter'>{checkoutText.footer}</div>
      <div className='checkoutSubRow'>
        <div className='uploadLogo'></div>
        <label htmlFor='fileUpload' className='orderButton fileUpload'>
          Choose Photo
        </label>
        <div className='fileDescriptionText'>
          {!!photoState.photo ? photoState.filename : 'No file chosen'}
        </div>

        <input
          type='file'
          accept='image/*'
          id='fileUpload'
          name='photo'
          onChange={handlePhoto}
          className='displayNone'
        />
      </div>

      {photoState.photo && (
        <img
          id='output'
          className='uploadedImg'
          alt=''
          src={photoState.fullSrc}
        />
      )}

      {error && <div className='checkoutError'>{error}</div>}
    </>
  );
};

export default PhotoIdForm;
