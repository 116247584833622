import React, { useState, useEffect } from "react";

import { introPage } from "../DataFiles/standardText";

import "../MainForm/MainForm.css";

const IntroPage = (props) => {
  const { goToForm, formData, setFormData } = props;

  const [isConsentChecked, setIsConsentChecked] = useState(false);

  const setChecked = () => {
    setIsConsentChecked(!isConsentChecked);
  };

  useEffect(() => {
    setFormData({
      ...formData,
      consentsSigned: isConsentChecked,
    });
  }, [isConsentChecked]);

  return (
    <div className="pageContainer">
      <div className="introContainer">
        <div className="introHeader">
          {/* <div className="messageHeader hiThere">Welcome to Medisuite</div> */}
          <div className="introSubheader">
            <div>{introPage.header}</div>
            <div className="introFooter">
              <div>{introPage.footer}</div>
              <div>
                We respect your data and privacy. Click{" "}
                <a
                  href="https://customerconsents.s3.amazonaws.com/Beluga_Health_PA_Privacy_Policy.pdf"
                  target="_blank"
                  rel="noreferrer"
                  className="link"
                >
                  here
                </a>{" "}
                to learn more.
              </div>
            </div>
          </div>
          <div className="introConsents">
            <input
              type="checkbox"
              name="consents"
              onClick={setChecked}
              className="checkBox"
            />
            <span>
              I agree to the{" "}
              <a
                href="https://customerconsents.s3.amazonaws.com/Beluga_Health_Telemedicine_Informed_Consent.pdf"
                target="_blank"
                rel="noreferrer"
                className="link"
              >
                Terms & Conditions
              </a>
            </span>
          </div>
          <button
            className="orderButton getStartedButton"
            onClick={goToForm}
            disabled={!isConsentChecked}
          >
            Get Started
          </button>
        </div>
      </div>
    </div>
  );
};

export default IntroPage;
