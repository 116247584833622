import React, { useRef } from "react";

import { checkoutText } from '../DataFiles/standardText';
import CheckoutFormContainer from './CheckoutFormContainer';

import "../MainForm/MainForm.css";

const SimpleSubmit = (props) => {
  const { formData, onPageUpdate, currentScreen } = props;
  const headerRef = useRef(null);
  const getHeaderText = () => {
    if (currentScreen === "photo") {
      return "";
    }
    return checkoutText.header;
  };


  return (
    <div className='checkoutContainer'>
      <div className='checkoutFormImage'></div>
      <div className='checkoutForm'>
        <div
          ref={headerRef}
          className='messageHeader'
          dangerouslySetInnerHTML={{ __html: getHeaderText() }}
        />
        <CheckoutFormContainer
          formData={formData}
          onPageUpdate={onPageUpdate}
          currentScreen={currentScreen}
          headerRef={headerRef}
        />
      </div>
    </div>
  );
};

export default SimpleSubmit;
