export const checkoutText = {
  header: `It looks like <span class="nowrap">ED treatment</span> may be right for you!`,
  body: "Your doctor consult will be provided by Beluga Health, a telemedicine provider that serves patients across the country. If medically appropriate, treatment may be prescribed to you. The doctor will contact you within the next 24 hours (often times much sooner) via email and text message to complete your consultation.",
  addition: "You will be notified once the Provider approves the prescription. MediSuite's pharmacy staff will contact you to answer any questions you may have about the prescribed medication. Payment will be made to MediSuite prior to shipment of Medication. Refills are valid for one year from the original script date.",
  footer: "In order to verify your identity, please upload a picture of your government-issued photo ID.",
};

export const introPage = {
  header: "The following questions will help our physicians determine if you're eligible for ED treatment.",
  footer: "MediSuite is contracted with a US licensed telemedicine provider.",
};

export const paymentSuccess = {
  header: "Thank you! Your information was submitted successfully.",
  body: "A doctor will now review your intake form. The doctor will contact you within the next 24 hours (often times much sooner) via email and text message to complete your consultation.",
  footer: "MediSuite is contracted with a US licensed telemedicine provider.",
};

export const dataSecure = {
  header:
    "Your personal information is securely transmitted via HIPAA compliant transfer to the Beluga Health medical team.",
};
