export const heightRange = [
  `3'0"`,
  `3'1"`,
  `3'2"`,
  `3'3"`,
  `3'4"`,
  `3'5"`,
  `3'6"`,
  `3'7"`,
  `3'8"`,
  `3'9"`,
  `3'10"`,
  `3'11"`,
  `4'0"`,
  `4'1"`,
  `4'2"`,
  `4'3"`,
  `4'4"`,
  `4'5"`,
  `4'6"`,
  `4'7"`,
  `4'8"`,
  `4'9"`,
  `4'10"`,
  `4'11"`,
  `5'0"`,
  `5'1"`,
  `5'2"`,
  `5'3"`,
  `5'4"`,
  `5'5"`,
  `5'6"`,
  `5'7"`,
  `5'8"`,
  `5'9"`,
  `5'10"`,
  `5'11"`,
  `6'0"`,
  `6'1"`,
  `6'2"`,
  `6'3"`,
  `6'4"`,
  `6'5"`,
  `6'6"`,
  `6'7"`,
  `6'8"`,
  `6'9"`,
  `6'10"`,
  `6'11"`,
  `7'0"`,
  `7'1"`,
  `7'2"`,
  `7'3"`,
  `7'4"`,
  `7'5"`,
  `7'6"`,
  `7'7"`,
  `7'8"`,
  `7'9"`,
  `7'10"`,
  `7'11"`,
  `8'0"`,
  `8'1"`,
  `8'2"`,
  `8'3"`,
  `8'4"`,
  `8'5"`,
  `8'6"`,
  `8'7"`,
  `8'8"`,
  `8'9"`,
  `8'10"`,
  `8'11"`,
];

export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const dates = () => {
  const datesArray = [];
  for (let i = 1; i < 32; i++) {
    datesArray.push(i);
  }
  return datesArray;
};

export const years = (startYear) => {
  const currentYear = new Date().getFullYear();
  const years = [];
  startYear = startYear || 1930;
  while (startYear <= currentYear) {
    years.push(startYear++);
  }
  return years;
};

export const patientPreferenceOptions = [
  {
    medication: "SILDENAFIL 20 MG",
    options: [
      // {
      //   name: "SILDENAFIL 20 MG",
      //   id: "nJG541l2TFjY7F1kiXz85GRb74Xjo64C",
      //   quantity: 180,
      //   dispense: "tablets",
      //   price: 150,
      //   refills: 3,
      //   strength: "20 MG",
      // },
      {
        name: "SILDENAFIL 20 MG",
        id: "nJG541l2TFjY7F1kiXz85GRb74Xjo64C",
        quantity: 90,
        dispense: "tablets",
        price: 95,
        refills: 6,
        strength: "20 MG",
      },
      {
        name: "SILDENAFIL 20 MG",
        id: "nJG541l2TFjY7F1kiXz85GRb74Xjo64C",
        quantity: 45,
        dispense: "tablets",
        price: 60,
        refills: 10,
        strength: "20 MG",
      },
    ],
  },
  {
    medication: "SILDENAFIL 50 MG (GENERIC VIAGRA®)",
    options: [
      // {
      //   name: "SILDENAFIL 50 MG (GENERIC VIAGRA®)",
      //   id: "7hTtwvBEXAsl59aY0pRfdEID589eCQpu",
      //   quantity: 60,
      //   dispense: "tablets",
      //   price: 170,
      //   refills: 5,
      //   strength: "50 MG",
      // },
      {
        name: "SILDENAFIL 50 MG (GENERIC VIAGRA®)",
        id: "7hTtwvBEXAsl59aY0pRfdEID589eCQpu",
        quantity: 30,
        dispense: "tablets",
        price: 95,
        refills: 8,
        strength: "50 MG",
      },
      {
        name: "SILDENAFIL 50 MG (GENERIC VIAGRA®)",
        id: "7hTtwvBEXAsl59aY0pRfdEID589eCQpu",
        quantity: 15,
        dispense: "tablets",
        price: 70,
        refills: 10,
        strength: "50 MG",
      },
    ],
  },
  {
    medication: "SILDENAFIL 100 MG (GENERIC VIAGRA®)",
    options: [
      // {
      //   name: "SILDENAFIL 100 MG (GENERIC VIAGRA®)",
      //   id: "AYZ1O8CKDvxRAa6Sh7vBIJYZtYizMATJ",
      //   quantity: 60,
      //   dispense: "tablets",
      //   price: 175,
      //   refills: 3,
      //   strength: "100 MG",
      // },
      {
        name: "SILDENAFIL 100 MG (GENERIC VIAGRA®)",
        id: "AYZ1O8CKDvxRAa6Sh7vBIJYZtYizMATJ",
        quantity: 30,
        dispense: "tablets",
        price: 100,
        refills: 6,
        strength: "100 MG",
      },
      {
        name: "SILDENAFIL 100 MG (GENERIC VIAGRA®)",
        id: "AYZ1O8CKDvxRAa6Sh7vBIJYZtYizMATJ",
        quantity: 15,
        dispense: "tablets",
        price: 75,
        refills: 9,
        strength: "100 MG",
      },
    ],
  },
  {
    medication: "TADALAFIL 5MG (GENERIC CIALIS®)",
    options: [
      // {
      //   name: "TADALAFIL 5MG (GENERIC CIALIS®)",
      //   id: "Z7N4qW9zMJ9KL4DOnXs2FkzwHIriZGZa",
      //   quantity: 180,
      //   dispense: "tablets",
      //   price: 150,
      //   refills: 2,
      //   strength: "5 MG",
      // },
      {
        name: "TADALAFIL 5MG (GENERIC CIALIS®)",
        id: "Z7N4qW9zMJ9KL4DOnXs2FkzwHIriZGZa",
        quantity: 90,
        dispense: "tablets",
        price: 95,
        refills: 4,
        strength: "5 MG",
      },
      {
        name: "TADALAFIL 5MG (GENERIC CIALIS®)",
        id: "Z7N4qW9zMJ9KL4DOnXs2FkzwHIriZGZa",
        quantity: 30,
        dispense: "tablets",
        price: 50,
        refills: 11,
        strength: "5 MG",
      },
    ],
  },
  {
    medication: "TADALAFIL 10MG (GENERIC CIALIS®)",
    options: [
      // {
      //   name: "TADALAFIL 10MG (GENERIC CIALIS®)",
      //   id: "zChbPSc8V4E1z08yUtpQBYe1txxZz6Rg",
      //   quantity: 60,
      //   dispense: "tablets",
      //   price: 160,
      //   refills: 5,
      //   strength: "10 MG",
      // },
      {
        name: "TADALAFIL 10MG (GENERIC CIALIS®)",
        id: "zChbPSc8V4E1z08yUtpQBYe1txxZz6Rg",
        quantity: 30,
        dispense: "tablets",
        price: 90,
        refills: 8,
        strength: "10 MG",
      },
      {
        name: "TADALAFIL 10MG (GENERIC CIALIS®)",
        id: "zChbPSc8V4E1z08yUtpQBYe1txxZz6Rg",
        quantity: 15,
        dispense: "tablets",
        price: 55,
        refills: 11,
        strength: "10 MG",
      },
    ],
  },
  {
    medication: "TADALAFIL 20MG (GENERIC CIALIS®)",
    options: [
      // {
      //   name: "TADALAFIL 20MG (GENERIC CIALIS®)",
      //   id: "1eKRguwOIgC8h6FRNLWCbwjgfZjBPiFJ",
      //   quantity: 60,
      //   dispense: "tablets",
      //   price: 175,
      //   refills: 5,
      //   strength: "20 MG",
      // },
      {
        name: "TADALAFIL 20MG (GENERIC CIALIS®)",
        id: "1eKRguwOIgC8h6FRNLWCbwjgfZjBPiFJ",
        quantity: 30,
        dispense: "tablets",
        price: 100,
        refills: 8,
        strength: "20 MG",
      },
      {
        name: "TADALAFIL 20MG (GENERIC CIALIS®)",
        id: "1eKRguwOIgC8h6FRNLWCbwjgfZjBPiFJ",
        quantity: 15,
        dispense: "tablets",
        price: 75,
        refills: 11,
        strength: "20 MG",
      },
    ],
  },
  // {
  //   medication: "VARDENAFIL 20MG (GENERIC LEVITRA®)",
  //   options: [
  //     {
  //       name: "VARDENAFIL 20MG (GENERIC LEVITRA®)",
  //       id: "JDlKcLLgE8IEBDL3fI5YQlT02Xt75RGw",
  //       quantity: 30,
  //       dispense: "tablets",
  //       price: 125,
  //       refills: 8,
  //       strength: "20 MG",
  //     },
  //     {
  //       name: "VARDENAFIL 20MG (GENERIC LEVITRA®)",
  //       id: "JDlKcLLgE8IEBDL3fI5YQlT02Xt75RGw",
  //       quantity: 15,
  //       dispense: "tablets",
  //       price: 75,
  //       refills: 11,
  //       strength: "20 MG",
  //     },
  //   ],
  // },
  {
    medication: "Let the doctor recommend",
    options: [
      {
        name: "SILDENAFIL 50 MG (GENERIC VIAGRA®)",
        id: "7hTtwvBEXAsl59aY0pRfdEID589eCQpu",
        quantity: 30,
        dispense: "tablets",
        price: 95,
        refills: 8,
        strength: "50 MG",
        isRecommended: true,
      },
    ],
  },
];

const patientPreferenceChoices = patientPreferenceOptions.reduce(
  (prev, cur) => {
    const group = cur.medication;
    const options = cur.options;
    prev.push({
      answer: {
        isText: true,
        value: group,
      },
    });
    options.forEach((opt) => {
      if (opt.isRecommended) {
        prev.push({
          answer: {
            displayName: "Let the doctor recommend",
            name: "Let the doctor recommend",
            group,
            optionId: opt.id,
            optionData: opt,
          },
        });
      } else {
        prev.push({
          answer: {
            name: `${opt.name} ${opt.quantity} ${opt.dispense} - $${opt.price} - (Refills - ${opt.refills})`,
            displayName: `${opt.quantity} ${opt.dispense} - $${opt.price} - (Refills - ${opt.refills})`,
            group,
            optionId: opt.id,
            optionData: opt,
          },
        });
      }
    });
    return prev;
  },
  []
);

export const questionnaire = [
  {
    question: "Please select your sex assigned at birth",
    answers: [
      {
        answer: "Female",
        error: {
          header: `ED treatment is indicated for the treatment of ED in men.`,
          body: `We recommend seeing a healthcare professional in-person to discuss whether ED treatment may be right for you.`,
        },
      },
      {
        answer: "Male",
      },
    ],
    type: "radio",
    standardDemo: "sex",
  },
  {
    question:
      "Do you ever have a problem getting or maintaining an erection that is rigid enough for sex?",
    answers: [
      {
        answer: "Yes - every time",
        showConditional: false,
      },
      {
        answer: "Yes - more than half the time",
        showConditional: false,
      },
      {
        answer: "Yes - on occasion",
        showConditional: false,
      },
      {
        answer: "Yes - but rarely",
        showConditional: false,
      },
      {
        answer:
          "I never have a problem getting or maintaining an erection for as long as I want",
        showConditional: false,
      },
    ],
    type: "radio",
    isConditional: false,
  },
  {
    question:
      "Have you ever been formally treated for ED or tried any medicines, vitamins, or supplements to treat it?",
    answers: [
      {
        answer: "Yes",
        showConditional: true,
      },
      {
        answer: "No",
        showConditional: false,
      },
    ],
    type: "radio",
    isConditional: false,
  },
  {
    question: "Please provide any details related to the treatment.",
    subText:
      "Include name, dosage, effectiveness, and any side effects. MediSuite's pharmacy staff will contact you to answer any questions you may have about the prescribed medication.",
    answers: ["freeText"],
    type: "freeText",
    isConditional: true,
  },
  {
    question:
      "Have you had a physical exam with a healthcare provider in the past 5 years?",
    answers: [
      {
        answer: "Yes, I am in good health",
        showConditional: false,
      },
      {
        answer: "Yes, but they found problems",
        showConditional: true,
      },
      {
        answer: "No",
        showConditional: false,
      },
    ],
    type: "radio",
    isConditional: false,
  },
  {
    question: "Please explain any issues during your last physical exam",
    answers: ["freeText"],
    type: "freeText",
    isConditional: true,
  },
  {
    question: "Was your blood pressure taken in the past year?",
    answers: [
      {
        answer: "Yes",
        showConditional: true,
      },
      {
        answer: "No, I will get a new blood pressure measurement",
        showConditional: false,
      },
    ],
    type: "radio",
    isConditional: false,
  },
  {
    question: "What was the value? (Example: 115/72)",
    answers: ["freeText"],
    type: "freeText",
    isConditional: true,
  },
  {
    question:
      "Please list any current medicines, vitamins or dietary supplements you take regularly.",
    subText: "Please include the dosage.",
    answers: ["selfReportedMeds"],
    type: "freeText",
    standardDemo: "selfReportedMeds",
    isConditional: false,
  },
  {
    question:
      "Do you take any of the following medicines? Select all that apply.",
    answers: [
      {
        answer:
          "Nitroglycerin spray, ointment, patches or tablets (Nitro-Dur, Nitrolingual, Nitrostat, Nitromist, Nitro-Bid, Transderm-Nitro, Nitro-Time, Deponit, Minitran, Nitrek, Nitrodisc, Nitrogard, Nitroglycn, Nitrol ointment, Nitrong, Nitro-Par)",
        showConditional: false,
        error: {
          header: `We recommend seeing a healthcare professional in-person to discuss whether ED medication may be right for you.`,
        },
      },
      {
        answer:
          "Isosorbide mononitrate, or isosorbide dinitrate (Isordil, Dilatrate, Sorbitrate, Imdur, Ismo, Monoket)",
        showConditional: false,
        error: {
          header: `We recommend seeing a healthcare professional in-person to discuss whether ED medication may be right for you.`,
        },
      },
      {
        answer: "Other medicines containing nitrates",
        showConditional: false,
        error: {
          header: `We recommend seeing a healthcare professional in-person to discuss whether ED medication may be right for you.`,
        },
      },
      {
        answer:
          "The alpha blocker medications: doxazosin (Cardura), prazosin (Minipress), terazosin (Hytrin)",
        showConditional: false,
        error: {
          header: `We recommend seeing a healthcare professional in-person to discuss whether ED medication may be right for you.`,
        },
      },
      {
        answer: "Riociguat (Adempas)",
        showConditional: false,
        error: {
          header: `We recommend seeing a healthcare professional in-person to discuss whether ED medication may be right for you.`,
        },
      },
      {
        answer: "None of these apply",
        showConditional: false,
      },
    ],
    isConditional: false,
    type: "checkBox",
  },
  {
    question: "Please list all known drug allergies:",
    answers: ["allergies"],
    type: "freeText",
    standardDemo: "allergies",
    isConditional: false,
  },
  {
    question: "Please list any prior medical conditions or serious problems:",
    answers: ["medicalConditions"],
    type: "freeText",
    standardDemo: "medicalConditions",
    isConditional: false,
  },
  {
    question:
      "Do any of the following cardiovascular risk factors apply to you? Select all that apply.",
    answers: [
      {
        answer: "Diabetes",
        showConditional: true,
        condId: "diabetes",
      },
      {
        answer: "High cholesterol",
        showConditional: true,
        condId: "cholesterol",
      },
      {
        answer: "High blood pressure",
        showConditional: true,
        condId: "highPressure",
      },
      {
        answer:
          "My father had a heart attack or heart disease at 55 years or younger",
        showConditional: false,
      },
      {
        answer:
          "My mother had a heart attack or heart disease at 65 year or younger",
        showConditional: false,
      },
      {
        answer: "None of these apply",
        showConditional: false,
      },
    ],
    type: "checkBox",
    isConditional: false,
  },
  {
    question: "Metaquestion",
    isConditional: true,
    subQuestions: [
      {
        question: "Tell us more about your diabetes.",
        subText:
          "When was your last Hemoglobin A1c checked and what was the value?",
        answers: ["freeText"],
        type: "freeText",
        isConditional: true,
        condId: "diabetes",
      },
      {
        question: "Tell us more about your high cholesterol",
        answers: ["freeText"],
        type: "freeText",
        isConditional: true,
        condId: "cholesterol",
      },
      {
        question: "Tell us more about your high blood pressure",
        answers: ["freeText"],
        type: "freeText",
        isConditional: true,
        condId: "highPressure",
      },
    ],
  },
  {
    question:
      "Do you have or have you previously been diagnosed with any of the following? Select all that apply.",
    answers: [
      {
        answer: "Prostate cancer",
        showConditional: true,
      },
      {
        answer: "Enlarged prostate (BPH)",
        showConditional: true,
      },
      {
        answer: "Kidney transplant or any condition affecting the kidney",
        showConditional: true,
      },
      {
        answer: "Multiple Sclerosis (MS) or similar disease",
        showConditional: true,
      },
      {
        answer: "Spinal injuries and/or paralysis",
        showConditional: true,
      },
      {
        answer: "Neurological diseases",
        showConditional: true,
      },
      {
        answer: "Stomach, intestinal, or bowel ulcers",
        showConditional: true,
      },
      {
        answer: "Heart arrhythmias (abnormal beating of the heart)",
        showConditional: true,
      },
      {
        answer:
          "Any acquired, congenital, or developmental abnormalities of the heart including heart murmurs",
        showConditional: true,
      },
      {
        answer: "None of these apply",
        showConditional: false,
      },
    ],
    type: "checkBox",
    isConditional: false,
  },
  {
    question: "Please tell us more about your diagnoses and treatment(s).",
    answers: ["freeText"],
    type: "freeText",
    isConditional: true,
    showPreviousAnswers: true,
  },
  {
    question:
      "Do you experience any of the following cardiovascular symptoms? Select all that apply.",
    answers: [
      {
        answer:
          "Chest pain or shortness of breath when climbing 2 flights of stairs or walking 4 blocks",
        showConditional: false,
        hasWarning: true,
        warning: "This may affect your eligibility for a prescription",
      },
      {
        answer: "Chest pain or shortness of breath with sexual activity",
        showConditional: false,
        hasWarning: true,
        warning: "This may affect your eligibility for a prescription",
      },
      {
        answer: "Unexplained fainting or dizziness",
        showConditional: false,
        hasWarning: true,
        warning: "This may affect your eligibility for a prescription",
      },
      {
        answer: "Prolonged cramping of the legs with exercise",
        showConditional: true,
        hasWarning: true,
        warning: "This may affect your eligibility for a prescription",
      },
      {
        answer: "Abnormal heart beats or rhythms",
        showConditional: false,
        hasWarning: true,
        warning: "This may affect your eligibility for a prescription",
      },
      {
        answer: "None of these apply",
        showConditional: false,
      },
    ],
    type: "checkBox",
    isConditional: false,
  },
  {
    question: "Please tell us more about your prolonged leg cramps.",
    answers: ["freeText"],
    type: "freeText",
    isConditional: true,
  },
  {
    question:
      "Do you use any of the following recreational drugs? Check all that apply.",
    subText:
      "Severe reactions may result if ED meds are used in conjunction with recreational drugs.",
    answers: [
      {
        answer: "Poppers or Rush",
        showConditional: true,
        hasWarning: true,
        warning: "This may affect your eligibility for a prescription",
      },
      {
        answer: "Amyl Nitrate or Butyl Nitrate",
        showConditional: true,
        hasWarning: true,
        warning: "This may affect your eligibility for a prescription",
      },
      {
        answer: "Cocaine",
        showConditional: true,
        hasWarning: true,
        warning: "This may affect your eligibility for a prescription",
      },
      {
        answer: "Cigarettes",
        showConditional: true,
        hasWarning: true,
        warning: "This may affect your eligibility for a prescription",
      },
      {
        answer: "Other",
        showConditional: true,
        hasWarning: true,
        warning: "This may affect your eligibility for a prescription",
      },
      {
        answer: `None of these apply`,
        showConditional: false,
      },
    ],
    type: "checkBox",
    isConditional: false,
  },
  {
    question:
      "Please explain your drug use. How frequently do you use them? When was the last time you used them?",
    answers: ["freeText"],
    type: "freeText",
    isConditional: true,
    showPreviousAnswers: true,
  },
  {
    question:
      "Do you have any of the following conditions? Select all that apply.",
    answers: [
      {
        answer: `A marked curve or bend in the penis that interferes with sex, or Peyronie's Disease`,
        showConditional: true,
        condId: "curve",
      },
      {
        answer: `Pain with erections or with ejaculation`,
        showConditional: false,
      },
      {
        answer: `A foreskin that is too tight`,
        showConditional: true,
        condId: "tight",
      },
      {
        answer: `None of these apply`,
        showConditional: false,
      },
    ],
    type: "checkBox",
    isConditional: false,
  },
  {
    question: "Metaquestion",
    isConditional: true,
    subQuestions: [
      {
        question:
          "Does the mark or curve prevent you from having sex? Is it painful? Have you sought treatment for this?",
        subText:
          "If so, what kind of treatment did you receive? Please explain further.",
        answers: ["freeText"],
        type: "freeText",
        isConditional: true,
        condId: "curve",
      },
      {
        question:
          "Does your tight foreskin cause pain when you have an erection? Does this interfere with sex? Are you able to pull your foreskin back and forth over the head of your penis?",
        subText: "Please explain further.",
        answers: ["freeText"],
        type: "freeText",
        isConditional: true,
        condId: "tight",
      },
    ],
  },
  {
    question:
      "Do you have now, or have you ever had any of the following conditions? Select all that apply",
    subText:
      "This information helps your doctor provide both effective and safe dosages of medication if appropriate.",
    answers: [
      {
        answer: "Priapism (erection lasting longer than 4 hours)",
        showConditional: true,
      },
      {
        answer: "Retinitis Pigmentosa",
        showConditional: true,
      },
      {
        answer: "Neurologic disease or stroke",
        showConditional: true,
      },
      {
        answer:
          "Blood clotting disorder, abnormal bleeding or bruising, or coagulopathy",
        showConditional: true,
      },
      {
        answer: "Stomach or intestinal ulcer",
        showConditional: true,
      },
      {
        answer: "A prior heart attack or heart failure",
        showConditional: true,
        condId: "heartAttack",
      },
      {
        answer: "Peripheral artery disease",
        showConditional: true,
      },
      {
        answer: "Any history of QT prolongation",
        showConditional: true,
      },
      {
        answer: "Sickle cell anemia, Myelomia, Leukemia",
        showConditional: true,
      },
      {
        answer: "Idiopathic Hypertrophic Subaortic Stenosis",
        showConditional: true,
      },
      {
        answer: "Use of blood thinners",
        showConditional: true,
      },
      {
        answer: "None of these apply",
        showConditional: false,
      },
    ],
    type: "checkBox",
    isConditional: false,
  },
  {
    question: "Metaquestion",
    isConditional: true,
    subQuestions: [
      {
        question: "Have you had a heart attack in the last three months?",
        answers: [
          {
            answer: "Yes",
            showConditional: false,
            error: {
              body: "",
              header: `We recommend seeing a healthcare professional in-person to discuss whether ED medication may be right for you.`,
            },
          },
          {
            answer: "No",
            showConditional: false,
          },
        ],
        type: "radio",
        condId: "heartAttack",
        isConditional: true,
      },
      {
        question: "Please tell us more about your condition(s).",
        answers: ["freeText"],
        type: "freeText",
        isConditional: true,
        showPreviousAnswers: true,
      },
    ],
  },
  {
    question:
      "Is there anything else you want your doctor to know about your condition or health?",
    answers: [
      {
        answer: "Yes",
        showConditional: true,
      },
      {
        answer: "No",
        showConditional: false,
      },
    ],
    type: "radio",
    isConditional: false,
  },
  {
    question:
      "Please enter anything else you want your doctor to know about your condition or health.",
    answers: ["freeText"],
    type: "freeText",
    isConditional: true,
  },
  {
    question: "Please Select An Option",
    type: "radio",
    answers: patientPreferenceChoices,
    standardDemo: "patientPreference",
  },
];
